import React from "react";
import { Card, CardContent } from "@mui/material";
import { CSSProperties, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import { usePaymentDetailQuery } from "../services/payment";
import moment from "moment";
import Loader from "../components/Loader";

export default function App() {
  const { paymentData, setPaymentId, isError } = usePaymentDetailQuery();
  const params = useParams();

  useEffect(() => {
    if (!params) return;
    const paymentId = params.payment;
    if (paymentId) {
      setPaymentId(paymentId);
    }
  }, [params, setPaymentId]);

  const paymentStatus: Record<
    string,
    { color: string; label: string; description: string }
  > = {
    created: {
      color: "#d4d00f",
      label: "Pago no completado",
      description:
        "El link de pago se ha creado, pero no se ha realizado ningun cobro. Crea un nuevo link de pago desde whatsapp.",
    },
    pending: {
      color: "#274bdb",
      label: "Pago en verificación",
      description:
        "El cobro se ha iniciado, pero no se ha sido acreditado por tu banco. Refresca la página para verificar si ya fue acreditado",
    },
    paid: {
      color: "#35ab3b",
      label: "🎉 Pago Exitoso",
      description:
        "El cobro ha sido verificado y acreditado con éxito. Los datos de tu tarjeta serán guardados para futuras operaciones, pero aún te pediremos el CVV por seguridad",
    },
  };

  if (isError) {
    return (
      <>
        <Header />
        <div style={sxErrorText}>
          La URL ingresada es incorrecta, prueba creando otro mensaje desde
          whatsapp
        </div>
      </>
    );
  }

  if (!paymentData) return <Loader />;

  return (
    <div style={sxGeneralContainer}>
      <Header />
      <Card sx={sxContainer}>
        <CardContent>
          <div style={sxTitle}>
            <div style={{ color: paymentStatus[paymentData.status].color }}>
              <b>{paymentStatus[paymentData.status].label}</b>
            </div>
            {paymentData.price && (
              <div>
                <b style={{ color: paymentStatus[paymentData.status].color }}>
                  S/ {(paymentData.price / 100).toFixed(2)}
                </b>
              </div>
            )}
          </div>
          <div style={sxPaymentDetails}>
            <div
              style={{
                fontSize: "20px",
                fontWeight: "600",
                textAlign: "center",
                marginBottom: "5px",
              }}
            >
              {paymentData.sellerName}
            </div>
            <div>
              <div style={sxFieldName}>Pago ID:</div>
              {paymentData.id}
            </div>
            {!!paymentData.price && (
              <div>
                <div style={sxFieldName}>Monto pagado al vendedor:</div> S/{" "}
                {(paymentData.price / 100).toFixed(2)}
              </div>
            )}
            {!!paymentData.price && !!paymentData.totalPrice && (
              <div>
                <div style={sxFieldName}>Comisión Inkapago:</div>
                S/{" "}
                {((paymentData.totalPrice - paymentData.price) / 100).toFixed(
                  2,
                )}
              </div>
            )}
            {!!paymentData.totalPrice && (
              <div>
                <div style={sxFieldName}>Total cobrado a tu tarjeta:</div> S/{" "}
                {(paymentData.totalPrice / 100).toFixed(2)}
              </div>
            )}
            <div>
              <div style={sxFieldName}>Detalle:</div>
              {paymentStatus[paymentData.status].description}
            </div>
            {paymentData.paymentDate && (
              <div>
                <div style={sxFieldName}>Fecha de Pago:</div>
                {moment(paymentData.paymentDate).format("DD/MM/YYYY h:mm a")}
              </div>
            )}
            <div>
              <div style={sxFieldName}>Fecha de Creación:</div>
              {moment(paymentData.created).format("DD/MM/YYYY h:mm a")}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

const sxTitle: CSSProperties = {
  margin: "10px auto 20px",
  textAlign: "center",
  fontWeight: "500",
  fontFamily: "Karla",
  fontSize: "24px",
  maxWidth: "300px",
  display: "flex",
  gap: "5px",
  flexDirection: "column",
};
const sxGeneralContainer: CSSProperties = {
  backgroundColor: "#ebebeb",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
};
const sxContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  maxWidth: "600px",
  width: "80%",
  minWidth: "300px",
  margin: "30px auto",
};
const sxPaymentDetails: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  fontFamily: "Karla",
  fontSize: "16px",
};
const sxFieldName: CSSProperties = {
  marginBottom: "10px",
  fontFamily: "Karla",
  fontWeight: "700",
};
const sxErrorText: CSSProperties = {
  color: "red",
  fontSize: "24px",
  fontFamily: "Karla",
  margin: "30px auto",
  textAlign: "center",
  padding: "30px",
};

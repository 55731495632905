import { useSearchParams } from "react-router-dom";
import { useSessionQuery } from "../services/payment";
import React, { CSSProperties, useEffect, useState } from "react";
import Header from "../components/Header";
import {
  CircularProgress,
  Container,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import WithdrawRequestsList from "../components/WithdrawRequestsList";
import Cookies from "js-cookie";

const ALLOWED_ROLES = ["admin", "superadmin"];

const WITHDRAW_REQUEST_STATUS = {
  CREATED: "created",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
};

export default function UserPayment() {
  const [params] = useSearchParams();
  const {
    sessionData,
    setEnabled: enableSessionData,
    isError: isSessionError,
  } = useSessionQuery({ enabled: false });
  const [currentStatus, setCurrentStatus] = useState(
    WITHDRAW_REQUEST_STATUS.CREATED,
  );

  useEffect(() => {
    if (!params) return;
    const token = params.get("token") ?? null;
    let authData: null | { token: string } = null;
    if (token) {
      authData = {
        token,
      };
    } else {
      const authDataCookie = Cookies.get("authData") ?? null;
      if (authDataCookie) {
        authData = JSON.parse(authDataCookie);
      }
    }
    if (authData) {
      Cookies.set("authData", JSON.stringify(authData), {
        sameSite: "strict",
        expires: 1,
      });
    }
    enableSessionData(true);
  }, [params, enableSessionData]);
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentStatus(newValue);
  };

  if (!sessionData) {
    return (
      <div style={sxGeneralWrapper}>
        <Header />
        <Container
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Container>
      </div>
    );
  }

  if (isSessionError) {
    return (
      <>
        <Header />
        <div style={sxErrorText}>
          No se pudo obtener los pedidos de retiro, revise su conexion e intente
          nuevamente
        </div>
      </>
    );
  }

  if (!ALLOWED_ROLES.includes(sessionData.role)) {
    return (
      <>
        <Header />
        <div style={sxErrorText}>
          Solo usuarios con el rol de Admin pueden acceder a esta página
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <Typography style={sxStoreTitle}>Pedidos de Retiro</Typography>
      <Tabs value={currentStatus} onChange={handleTabChange}>
        <Tab value={WITHDRAW_REQUEST_STATUS.CREATED} label="Creado" />
        <Tab value={WITHDRAW_REQUEST_STATUS.IN_PROGRESS} label="En Progreso" />
        <Tab value={WITHDRAW_REQUEST_STATUS.COMPLETED} label="Completado" />
      </Tabs>
      <WithdrawRequestsList status={currentStatus} sessionData={sessionData} />
    </>
  );
}

const sxGeneralWrapper: CSSProperties = {
  position: "relative",
  minHeight: "100vh",
  minWidth: "300px",
  fontFamily: "Karla",
};

const sxStoreTitle: CSSProperties = {
  margin: "40px auto 30px",
  textAlign: "center",
  fontWeight: "700",
  fontFamily: "Karla",
  fontSize: "25px",
  maxWidth: "300px",
};

const sxErrorText: CSSProperties = {
  color: "red",
  fontSize: "24px",
  fontFamily: "Karla",
  margin: "30px auto",
  textAlign: "center",
  padding: "30px",
};

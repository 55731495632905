import React from "react";
import { useEffect, useState, CSSProperties, useRef } from "react";
import { CardPayment } from "@mercadopago/sdk-react";
import Header from "../components/Header";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { Button, Radio, RadioGroup } from "@mui/material";
import {
  useCreatePaymentIntent,
  useCreatePaymentWithSavedCard,
  usePaymentMethodsQuery,
  useSessionQuery,
} from "../services/payment";
import {
  ICardPaymentBrickPayer,
  ICardPaymentFormData,
} from "@mercadopago/sdk-react/bricks/cardPayment/type";
import { toast } from "react-toastify";
import { MercadoPagoInstance } from "@mercadopago/sdk-react/mercadoPago/initMercadoPago";
import { TInstanceMercadoPago } from "@mercadopago/sdk-react/mercadoPago/initMercadoPago/type";
import { Field } from "@mercadopago/sdk-react/secureFields/util/types";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { computeInkapagoComission } from "../utils/price";

const CardDetails = () => {
  const [price, setPrice] = useState<number | null>(null);
  const navigate = useNavigate();
  const { sessionData } = useSessionQuery();
  const createPaymentIntent = useCreatePaymentIntent();
  const createPaymentWithSavedCard = useCreatePaymentWithSavedCard();
  const { paymentMethods, isSuccess: paymentMethodsSuccess } =
    usePaymentMethodsQuery(sessionData);
  const [paymentMethodId, setPaymentMethod] = useState("");
  const newCardId = "newCard";

  const [mpInstance, setMpInstance] = useState<TInstanceMercadoPago | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const securityCode = useRef<Field | null>(null);
  const securityCodeMounted = useRef<boolean>(false);
  const securityCodeElement = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const setMercadoPago = async () => {
      const instanceMercadoPago = await MercadoPagoInstance.getInstance();
      if (instanceMercadoPago) {
        setMpInstance(instanceMercadoPago);
      }
    };
    setMercadoPago();
  }, [setMpInstance]);

  useEffect(() => {
    if (!mpInstance || !!securityCode.current) return;
    securityCode.current = mpInstance.fields.create("securityCode", {
      placeholder: "Ingresa el CVV",
      mode: "mandatory",
      style: { fontFamily: "Karla", fontSize: "14px" },
      customFonts: [
        {
          src: "https://fonts.googleapis.com/css2?family=Karla&display=swap",
        },
      ],
    });
  }, [mpInstance]);

  useEffect(() => {
    const rawPrice = window.localStorage.getItem("price");
    if (!rawPrice) {
      navigate("/pago/monto");
    } else {
      setPrice(Number(rawPrice));
    }
  }, [navigate]);

  useEffect(() => {
    if (!sessionData) return;
    if (sessionData.payment) {
      if (sessionData.payment.status !== "created") {
        navigate(`/status/${sessionData.payment._id}`);
      }
    }
  }, [sessionData, navigate]);

  useEffect(() => {
    if (securityCodeElement) mountSecurityCode(paymentMethodId);
  }, [paymentMethodId, securityCodeElement]);

  useEffect(() => {
    if (!paymentMethodsSuccess || paymentMethods === undefined) return;
    const cardId = paymentMethods.length > 0 ? paymentMethods[0].id : newCardId;
    setPaymentMethod(cardId);
  }, [paymentMethods, paymentMethodsSuccess]);

  const onCardInfoSubmit = async (
    data: ICardPaymentFormData<ICardPaymentBrickPayer>,
  ) => {
    setLoading(true);
    createPaymentIntent.mutateAsync(
      {
        issuerId: String(data.issuer_id),
        token: data.token,
        paymentMethodId: data.payment_method_id,
        price: price as number,
        email: data.payer.email ?? "test@inkapago.com",
        user_id: {
          type: data.payer.identification?.type,
          number: data.payer.identification?.number,
        },
      },
      {
        onSuccess: () => {
          toast.success("🎉 Pago exitoso");
          if (sessionData && sessionData.payment) {
            navigate(`/status/${sessionData.payment._id}`);
          }
        },
        onError: err => {
          console.error(
            "No se pudo realizar el pago, inténtalo de nuevo o más tarde",
            (err as Error).message,
          );
          toast.error(
            "No se pudo realizar el pago, inténtalo de nuevo o más tarde",
          );
          setLoading(false);
        },
      },
    );
  };

  const mountSecurityCode = (cardId: string) => {
    if (!securityCode.current) return;

    if (securityCodeMounted.current) {
      securityCode.current.unmount();
      securityCodeMounted.current = false;
    }

    if (cardId === newCardId) return;
    securityCode.current.mount(`securityCode${cardId}`);
    securityCodeMounted.current = true;
  };

  const handleCardSelectorChange = (data: { target: { value: string } }) => {
    const cardId = data.target.value;
    setPaymentMethod(cardId);
    mountSecurityCode(cardId);
  };

  const onCVVSubmit = async () => {
    if (!mpInstance) return;
    if (!paymentMethods) return;
    setButtonDisabled(true);
    let token;
    try {
      const createdToken = await mpInstance.fields.createCardToken({
        cardId: paymentMethodId,
      });
      token = createdToken.id;
    } catch (err) {
      toast.error("Revisa que el CVV de la tarjeta sea correcto");
      setButtonDisabled(false);
      return;
    }
    const paymentMethodData = paymentMethods?.find(
      method => method.id === paymentMethodId,
    );
    if (!paymentMethodData) return;
    setLoading(true);
    createPaymentWithSavedCard.mutateAsync(
      {
        token: token,
        paymentMethodId: paymentMethodData.id,
        price: price as number,
      },
      {
        onSuccess: () => {
          toast.success("🎉 Pago exitoso");
          if (sessionData && sessionData.payment) {
            navigate(`/status/${sessionData.payment._id}`);
          }
        },
        onError: err => {
          console.error(
            "No se pudo realizar el pago, inténtalo de nuevo o más tarde",
            (err as Error).message,
          );
          toast.error(
            "Revisa que el CVV sea correcto e inténtalo de nuevo o más tarde",
          );
          navigate(0);
          setLoading(false);
        },
      },
    );
  };

  if (loading || !sessionData || !paymentMethodId || !securityCode)
    return <Loader />;

  return (
    <>
      <Header />
      <div style={{ maxWidth: "479px", margin: "20px auto 30px" }}>
        <div style={sxStoreTitle}>{sessionData.destUserName ?? ""}</div>
        {!!price && sessionData && (
          <>
            <div style={sxPrice}>
              <div style={sxPriceMount}>
                Cobraremos{" "}
                <b>
                  S/
                  {((price + computeInkapagoComission(price)) / 100).toFixed(2)}
                </b>{" "}
                a tu tarjeta
              </div>
              <Button onClick={() => navigate("/pago/monto")} style={sxButtonEditPrice}>
                Editar monto
              </Button>
            </div>

            {paymentMethods && paymentMethods.length > 0 && (
              <div style={sxCardList}>
                <div style={sxInfoCCV}>
                  Selecciona una tarjeta e ingresa su CVV por seguridad
                </div>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={paymentMethodId}
                  name="radio-buttons-group"
                  onChange={handleCardSelectorChange}
                >
                  {paymentMethods.map((paymentMethod, idx) => (
                    <div
                      key={idx}
                      style={{
                        ...sxCardItem,
                        ...(paymentMethod.id === paymentMethodId
                          ? sxCardSelectedItem
                          : undefined),
                      }}
                    >
                      <Radio value={paymentMethod.id} />
                      <img
                        src={paymentMethod.thumbnail}
                        style={{
                          width: "20px",
                          marginRight: "10px",
                          justifyContent: "center",
                        }}
                        alt="card brand"
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span>....{paymentMethod.lastFour}</span>
                        <div
                          id={`securityCode${paymentMethod.id}`}
                          ref={securityCodeElement}
                          style={{
                            marginTop: "-1px",
                            height: "20px",
                            width: "100px",
                            padding: "0px 5px",
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  <div
                    style={{
                      ...sxCardItem,
                      ...(newCardId === paymentMethodId
                        ? sxCardSelectedItem
                        : undefined),
                    }}
                  >
                    <Radio value={newCardId} /> Nueva tarjeta de crédito o
                    débito
                  </div>
                </RadioGroup>
              </div>
            )}

            {paymentMethodId === newCardId ? (
              <>
                <div style={{ height: "10px" }} />
                <CardPayment
                  initialization={{ amount: price }}
                  customization={{
                    paymentMethods: { minInstallments: 1, maxInstallments: 1 },
                  }}
                  locale="es-PE"
                  onSubmit={onCardInfoSubmit}
                />
              </>
            ) : (
              <Button
                disabled={buttonDisabled}
                onClick={onCVVSubmit}
                style={{
                  width: "calc(100% - 40px)",
                  backgroundColor: "#009EE3",
                  color: "white",
                  textTransform: "capitalize",
                  fontWeight: "700",
                  margin: "30px 20px",
                  height: "46px",
                  fontSize: "16px",
                  borderRadius: "10px",
                }}
              >
                <LockOutlinedIcon
                  style={{ fontSize: "16px", marginRight: "10px" }}
                />{" "}
                Pagar
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
};

const sxPrice: CSSProperties = {
  padding: "16px 16px 0px",
  display: "flex",
  flexDirection: "column",
};
const sxPriceMount: CSSProperties = {
  color: "#1A1A1A",
  fontSize: "18px",
  fontWeight: "400",
  textAlign: "center",
};
const sxButtonEditPrice: CSSProperties = {
  alignSelf: "center",
  width: "200px",
};
const sxStoreTitle: CSSProperties = {
  margin: "40px auto 0px",
  textAlign: "center",
  fontWeight: "700",
  fontFamily: "Karla",
  fontSize: "18px",
  maxWidth: "300px",
};
const sxCardList: CSSProperties = {
  padding: "25px 15px 0px",
};
const sxCardItem: CSSProperties = {
  alignItems: "center",
  display: "flex",
  fontFamily: "Karla",
  fontWeight: "500",
};
const sxCardSelectedItem: CSSProperties = {
  borderRadius: "10px",
  border: "1px solid #1976d2",
  borderColor: "#1976d2",
};
const sxInfoCCV: CSSProperties = {
  padding: "0px 5px 20px",
  fontSize: "16px",
  fontFamily: "Karla",
};

export default CardDetails;

import React from "react";
import { CSSProperties, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../components/Header";

const ErrorMessage = () => {
  const [params] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const error = params?.get("error");
    if (error) {
      switch (error) {
        case "SESSION_EXPIRED": {
          setErrorMessage(
            "El link de pago no es válido, crea un nuevo pago desde Whatsapp",
          );
          break;
        }
      }
    }
  }, [params]);

  return (
    <>
      <Header />
      <div style={sxErrorText}>{errorMessage}</div>
    </>
  );
};

const sxErrorText: CSSProperties = {
  color: "red",
  fontSize: "24px",
  fontFamily: "Karla",
  margin: "30px auto",
  textAlign: "center",
  padding: "30px",
};

export default ErrorMessage;

import { useSellerWithdrawRequestsQuery } from "../services/payment";
import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  Pagination,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, CSSProperties, useState } from "react";
import { getPENFromCents } from "../utils/price";
import moment from "moment/moment";

const STATUS_TRANSLATE = new Map<string, string>([
  ["created", "Creado"],
  ["in_progress", "En progreso"],
  ["completed", "Completado"],
]);
export default function UserWithdrawRequestsList({ ...props }) {
  const { sessionData } = props;
  const PAGE_SIZE = 5;
  const [page, setPage] = useState(1);
  const { withdrawRequests, isLoading, isError } =
    useSellerWithdrawRequestsQuery(sessionData, PAGE_SIZE, page);

  const handlePageChange = (event: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  if (isLoading) {
    return (
      <div>
        <Container
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Container>
      </div>
    );
  }
  if (isError) {
    return (
      <>
        <div style={sxErrorText}>
          No se pudo obtener los pedidos de retiro, intente nuevamente
        </div>
      </>
    );
  }

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginTop: "10px",
      }}
    >
      {withdrawRequests.results.map(withdraw => {
        return (
          <Card key={withdraw.id} variant="outlined">
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <Typography variant="body2" color="text.secondary">
                <strong>Monto:</strong> {getPENFromCents(withdraw.amount)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Estado:</strong> {STATUS_TRANSLATE.get(withdraw.status)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Fecha de creación:</strong>{" "}
                {withdraw?.created
                  ? moment(withdraw.created).format("DD/MM/YYYY h:mm a")
                  : "-"}
              </Typography>
            </CardContent>
          </Card>
        );
      })}
      <Container>
        <Pagination
          sx={{ display: "flex", justifyContent: "center" }}
          count={Math.ceil(withdrawRequests.total / PAGE_SIZE)}
          onChange={handlePageChange}
          page={page}
        />
      </Container>
    </Container>
  );
}

const sxErrorText: CSSProperties = {
  color: "red",
  fontSize: "24px",
  fontFamily: "Karla",
  margin: "30px auto",
  textAlign: "center",
  padding: "30px",
};

import { Routes, Route, useLocation } from "react-router-dom";
import CardDetails from "./pages/CardDetails";
import RegisterSeller from "./pages/RegisterSeller";
import ErrorMessage from "./pages/ErrorMessage";
import PriceInput from "./pages/PriceInput";
import RegisterAdmin from "./pages/RegisterAdmin";
import PaymentStatus from "./pages/PaymentStatus";
import ShortSecureURL from "./pages/ShortSecureURL";
import React from "react";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import UserPayment from "./pages/UserPayments";
import WithdrawRequests from "./pages/WithdrawRequests";
import HomePage from "./pages/HomePage";

function App() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/pago/monto" element={<PriceInput />} />
      <Route path="/pago" element={<CardDetails />} />
      <Route path="/admin" element={<RegisterAdmin />} />
      <Route path="/register" element={<RegisterSeller />} />
      <Route path="/status/:payment" element={<PaymentStatus />} />
      <Route path="/error" element={<ErrorMessage />} />
      <Route path="/pago-seguro/:shortStr" element={<ShortSecureURL />} />
      <Route path="/payments" element={<UserPayment />} />
      <Route path="/withdraw-requests" element={<WithdrawRequests />} />
    </Routes>
  );
}

export default App;

import { useUserPaymentsQuery } from "../services/payment";
import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  Pagination,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, CSSProperties, useState } from "react";
import { getPENFromCents } from "../utils/price";
import moment from "moment/moment";

export default function UserPayment({ ...props }) {
  const { sessionData } = props;
  const PAGE_SIZE = 5;
  const [page, setPage] = useState(1);
  const { userPayments, isLoading, isError } = useUserPaymentsQuery(
    sessionData,
    PAGE_SIZE,
    page,
  );

  const handlePageChange = (event: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  if (isLoading) {
    return (
      <div>
        <Container
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Container>
      </div>
    );
  }
  if (isError) {
    return (
      <>
        <div style={sxErrorText}>
          No se pudo obtener los pagos, refresque la página e intente nuevamente
        </div>
      </>
    );
  }

  return (
    <Container sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {userPayments.results?.map(payment => {
        return (
          <Card key={payment.id} variant="outlined">
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                border: payment.withdrawn
                  ? "green"
                  : payment.withdrawnRequestId
                  ? "#00AAE4"
                  : "orange",
                borderWidth: "3px",
                borderStyle: "solid",
                borderRadius: "10px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    color: payment.withdrawn
                      ? "green"
                      : payment.withdrawnRequestId
                      ? "#00AAE4"
                      : "orange",
                  }}
                >
                  {" "}
                  {getPENFromCents(payment.price)}{" "}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ alignItems: "center", display: "flex" }}
                >
                  {payment.customer?.phone}
                </Typography>
              </div>
              <Typography variant="body2" color="text.secondary">
                <strong>Estado:</strong> Pagado
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Fecha de creación:</strong>{" "}
                {payment?.created
                  ? moment(payment.created).format("DD/MM/YYYY h:mm a")
                  : "-"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Fecha de Pago:</strong>{" "}
                {payment?.created
                  ? moment(payment.paymentDate).format("DD/MM/YYYY h:mm a")
                  : "-"}
              </Typography>
              {payment.withdrawn ? (
                <Typography color="green">RETIRADO</Typography>
              ) : payment.withdrawnRequestId ? (
                <Typography fontWeight="600" color="#00AAE4">
                  EN PROCESO DE RETIRO
                </Typography>
              ) : (
                <Typography fontWeight="600" color="orange">
                  DISPONIBLE PARA RETIRO
                </Typography>
              )}
            </CardContent>
          </Card>
        );
      })}
      <Container>
        <Pagination
          sx={{ display: "flex", justifyContent: "center" }}
          count={Math.ceil(userPayments.total / PAGE_SIZE)}
          onChange={handlePageChange}
          page={page}
        />
      </Container>
    </Container>
  );
}

const sxErrorText: CSSProperties = {
  color: "red",
  fontSize: "24px",
  fontFamily: "Karla",
  margin: "30px auto",
  textAlign: "center",
  padding: "30px",
};

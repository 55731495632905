import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { initMercadoPago } from "@mercadopago/sdk-react";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import queryClient from "./services/queryClient";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID as string, {
  gtagOptions: { send_page_view: false },
});
initMercadoPago(process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY as string);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
      <ToastContainer />
    </QueryClientProvider>
  </BrowserRouter>,
);

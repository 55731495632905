import React from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        navigate("/");
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          fontFamily: "Karla",
          fontWeight: "700",
          fontSize: "18px",
        }}
      >
        <div style={{ marginRight: "10px" }}>Inkapago</div>
        <img src="/img/securityCheck.svg" width="20" alt="inkapago" />
      </div>
    </div>
  );
};

export default Header;

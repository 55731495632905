import React, { CSSProperties, useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MenuIcon from "@mui/icons-material/Menu";

export default function HomePage() {
  const theme = useTheme();
  const isMobileMiddle = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [isOpenMenuForMobile, setIsOpenMenuForMobile] = useState(false);
  const [businessNumber, setBusinessNumber] = useState("");
  const [inputError, setInputError] = useState(false);

  const injectStyle = (style: string) => {
    const styleElement = document.createElement("style");
    document.head.appendChild(styleElement);
    const styleSheet = styleElement.sheet;
    styleSheet?.insertRule(style, styleSheet.cssRules.length);
  };

  useEffect(() => {
    let number = businessNumber;
    if (/^\d{9}$/.test(businessNumber)) number = "+51" + businessNumber;
    if (
      !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(number) &&
      number !== ""
    ) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  }, [businessNumber]);

  const formatBusinessNumber = (bNumber: string) => {
    if (/^\d{9}$/.test(bNumber)) return bNumber;
    return bNumber;
  };

  injectStyle(`
      @-webkit-keyframes grow-width {
        0%   { width: 0%; }
        100%  { width: 100%; }
      }
    `);

  const formattedBusinessNumber = formatBusinessNumber(businessNumber);
  const isNavBarLeft = !isMobileMiddle;
  const isNavBarDisplayed = isNavBarLeft || isOpenMenuForMobile;

  return (
    <div
      style={{
        fontFamily: "Karla",
        margin: "0 0 0 0",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isNavBarDisplayed ? (
        <div
          className="navbar-lg"
          style={{
            width: isMobileMiddle ? "100%" : "300px",
            backgroundColor: "#000",
            position: "fixed",
            height: "100%",
            ...(isMobileMiddle && isNavBarDisplayed
              ? {
                  animation: "grow-width 100ms linear",
                  animationFillMode: "forwards",
                }
              : {}),
            zIndex: "3",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "2rem",
              gap: "1.5rem",
              paddingTop: "2rem",
              fontWeight: 800,
              fontFamily: "Lato",
            }}
          >
            <a
              href="#intro-pay"
              onClick={event => {
                event.preventDefault();
                setIsOpenMenuForMobile(false);
                const el = document.getElementById("intro-pay");
                el?.scrollIntoView({ block: "start", behavior: "smooth" });
              }}
              style={{
                textDecoration: "none",
                backgroundColor: "#000",
                border: "none",
                color: "#fff",
                padding: 0,
              }}
            >
              Pagar con whatsapp
            </a>
            <a
              href="#how-to-pay"
              onClick={event => {
                event.preventDefault();
                setIsOpenMenuForMobile(false);
                const el = document.getElementById("how-to-pay");
                el?.scrollIntoView({ block: "start", behavior: "smooth" });
              }}
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
            >
              ¿Cómo pagar?
            </a>
            <a
              href="#security"
              onClick={event => {
                event.preventDefault();
                setIsOpenMenuForMobile(false);
                const el = document.getElementById("security");
                el?.scrollIntoView({ block: "start", behavior: "smooth" });
              }}
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
            >
              Seguridad
            </a>
            <a
              href="#contact-us"
              onClick={event => {
                event.preventDefault();
                setIsOpenMenuForMobile(false);
                const el = document.getElementById("contact-us");
                el?.scrollIntoView({ block: "start", behavior: "smooth" });
              }}
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
            >
              Comunícate con nosotros
            </a>
          </div>
        </div>
      ) : (
        <div
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            display: "flex",
            justifyContent: "end",
            backgroundColor: "#000",
            color: "#fff",
            minHeight: "40px",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
            zIndex: 1,
          }}
        >
          <MenuIcon
            fontSize="large"
            onClick={() => setIsOpenMenuForMobile(true)}
          />
        </div>
      )}
      <div
        className="content"
        style={{
          marginLeft: isMobileMiddle ? 0 : "300px",
          paddingTop: "3rem",
          zIndex: 1,
        }}
      >
        <div
          id="intro-pay"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingBottom: "4rem",
          }}
        >
          <div style={titleStyle}>
            <div style={{ maxWidth: "80%" }}>
              Inkapago: paga con tarjeta enviando un mensaje de whatsapp
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isMobileSmall ? "column" : "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 12,
              marginBottom: "3rem",
            }}
          >
            <input
              style={{
                width: "16rem",
                borderRadius: "0.25rem",
                borderColor: inputError ? "red" : "black",
                fontSize: "1.125rem",
                paddingBottom: "1rem",
                paddingLeft: "0.75rem",
                paddingTop: "1rem",
                borderStyle: "solid",
                borderWidth: inputError ? "3px" : "1px",
              }}
              placeholder="Teléfono del negocio a pagar"
              value={businessNumber}
              onChange={event => setBusinessNumber(event.target.value)}
            />
            <a
              style={buttonStyle}
              href={
                inputError
                  ? "#"
                  : `https://api.whatsapp.com/send/?phone=12543293155&text=${formattedBusinessNumber}&type=phone_number`
              }
            >
              Pagar ahora
            </a>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img
              style={{ width: isMobileSmall ? "80%" : "50%" }}
              alt="image"
              src="/img/llamaman.bin"
            />
          </div>
        </div>
        <div
          id="how-to-pay"
          style={{
            display: "flex",
            flexDirection: isMobileMiddle ? "column" : "row",
            backgroundColor: "#803f53",
            color: "#fff",
            paddingTop: "3rem",
            paddingBottom: "3rem",
            paddingLeft: 0,
            paddingRight: 0,
            flexWrap: "wrap",
          }}
        >
          <div
            className="qr-content"
            style={{
              display: "flex",
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              width: isMobileMiddle ? "100%" : "50%",
            }}
          >
            <img
              style={{
                borderRadius: "1rem",
                width: "50%",
                height: "fit-content",
              }}
              src="/img/qr.png"
              alt="qr image"
            />
          </div>
          <div
            className="steps"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              fontSize: "1.25rem",
              fontWeight: 700,
              width: isMobileMiddle ? "100%" : "50%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                marginRight: 15,
                marginLeft: 15,
                paddingTop: "3rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div>
                  <OpenInNewIcon fontSize="large" />
                </div>
                <div>Envíanos un mensaje de whatsapp o escanea el QR</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <EditIcon fontSize="large" />
                <div>
                  Escribe &quot;pago&quot; y el número del negocio a pagar
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CreditCardIcon fontSize="large" />
                <div>
                  Ingresa al link que te enviaremos, indica el monto y listo
                </div>
              </div>
            </div>
            <div
              className="link-whatsapp"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <a
                style={{
                  textDecoration: "none",
                  fontSize: "1.25rem",
                  letterSpacing: "0.05rem",
                  fontWeight: 900,
                  color: "#f7f7f7",
                  display: "flex",
                  alignItems: "center",
                }}
                href="https://api.whatsapp.com/send/?phone=12543293155&text&type=phone_number"
              >
                <p>Ir a Whatsapp</p>
                <ArrowForwardIcon></ArrowForwardIcon>
              </a>
            </div>
          </div>
        </div>
        <div
          id="security"
          style={{
            paddingBottom: "4rem",
            paddingTop: "2rem",
            display: "flex",
            flexDirection: isMobileMiddle ? "column" : "row",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: isMobileMiddle ? "center" : "left",
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
          >
            <h5 style={{ fontSize: "1.875rem" }}>
              Realiza tus inkapagos de manera segura
            </h5>
            <div
              style={{ fontSize: "1.25rem", fontWeight: 500, letterSpacing: 0 }}
            >
              <p>- Verificación de conexión segura en cada pago</p>
              <p>- Autenticación de enlace magico por medio de whatsapp</p>
              <p>
                - No guardamos los datos de tu tarjetas, solo la infomación para
                identificarla
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="cuy"
              src="/img/cuy.bin"
              style={{ width: isMobileMiddle ? "50%" : "80%" }}
            />
          </div>
        </div>
        <div
          id="contact-us"
          style={{
            backgroundColor: "#803f53",
            color: "#fff",
            flexDirection: isMobileMiddle ? "column" : "row",
            alignItems: "center",
            textAlign: "center",
            paddingTop: "4rem",
            paddingBottom: "4rem",
            display: "flex",
          }}
        >
          <div style={{ width: "50%" }}>
            <img
              style={{ borderRadius: "9999rem", width: "80%" }}
              alt="alpaca"
              src="/img/alpaca.bin"
            />
          </div>
          <div style={{ width: isMobileMiddle ? "80%" : "40%" }}>
            <h1
              style={{
                fontSize: "1.875rem",
                fontWeight: 900,
                fontFamily: "Lato",
              }}
            >
              {" "}
              Comunicate con nosotros{" "}
            </h1>
            <p style={{ marginBottom: "2.25rem" }}>
              {" "}
              Escribe un correo a info@inkapago.com y hablemos de tu proyecto o
              negocio{" "}
            </p>
            <a
              style={{
                backgroundColor: "#ff4954",
                color: "#ffffff",
                fontWeight: 700,
                fontSize: "1.125rem",
                textDecoration: "none",
                padding: "0.75rem 2.5rem 0.75rem 2.5rem",
                borderRadius: "9999rem",
              }}
              href="mailto:info@inkapago.com"
            >
              {" "}
              Enviar correo
            </a>
          </div>
        </div>
        <div
          id="footer"
          style={{ width: "80%", marginRight: "10%", marginLeft: "10%" }}
        >
          <p>© 2023 Inkapago. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}

const titleStyle: CSSProperties = {
  fontWeight: 700,
  fontSize: "1.87rem",
  letterSpacing: "0rem",
  lineHeight: 1.5,
  color: "#474747",
  textAlign: "center",
  justifyContent: "center",
  display: "flex",
  paddingBottom: "1.25rem",
  marginBottom: "1.5rem",
};

const buttonStyle: CSSProperties = {
  backgroundColor: "#ff4954",
  color: "white",
  fontWeight: 700,
  fontSize: "1.25rem",
  border: 0,
  borderRadius: "0.25rem",
  padding: "1rem 0.75rem 1rem 0.75rem",
  textDecoration: "none",
};

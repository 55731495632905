import React from "react";
import { CSSProperties, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Loader from "../components/Loader";
import { useShortURLQuery } from "../services/shortURL";

const ShortURL = () => {
  const params = useParams();
  const { shortURLData, setshortStr, isError } = useShortURLQuery();

  useEffect(() => {
    if (!params) return;
    const shortStr = params.shortStr;
    if (shortStr) {
      setshortStr(shortStr);
    }
  }, [params, setshortStr]);

  useEffect(() => {
    if (shortURLData) {
      window.location.href = shortURLData.fullURL;
    }
  }, [shortURLData]);

  if (isError) {
    return (
      <>
        <Header />
        <div style={sxErrorText}>
          La URL ingresada es incorrecta, prueba creando otro mensaje desde
          whatsapp
        </div>
      </>
    );
  }

  return <Loader />;
};

const sxErrorText: CSSProperties = {
  color: "red",
  fontSize: "24px",
  fontFamily: "Karla",
  margin: "30px auto",
  textAlign: "center",
  padding: "30px",
};

export default ShortURL;

import { AxiosError } from "axios";
import { DefaultOptions, QueryClient, QueryClientConfig } from "react-query";

export const handleError = () => {
  return (error: unknown) => {
    const axiosError = error as AxiosError;

    if ([401, 403].includes(axiosError.response?.status as number)) {
      window.location.href = "/error?error=SESSION_EXPIRED";
      return;
    }
  };
};

const defaultOptions: DefaultOptions<unknown> = {
  queries: {
    onError: handleError(),
    retry: false,
  },
};

const config: QueryClientConfig = {
  defaultOptions,
};

const queryClient = new QueryClient(config);

export default queryClient;

import { useState } from "react";
import { inkapagoAPI } from "./axios.config";
import { useMutation, useQuery } from "react-query";

type SessionResponse = {
  sourceUserId: string;
  destUserId?: string;
  destUserName?: string;
  paymentId?: string;

  role: string;
  action: string;

  payment?: {
    _id: string;
    status: string;
  };
};

export const useSessionQuery = (options?: { enabled: boolean }) => {
  const [enabled, setEnabled] = useState(options?.enabled ?? true);

  const query = useQuery(
    ["sessionData"],
    () => {
      return inkapagoAPI.get("/session");
    },
    {
      refetchOnWindowFocus: false,
      enabled,
    },
  );

  return {
    ...query,
    sessionData: query.data?.data as SessionResponse | undefined,
    setEnabled,
  };
};

type CreatePaymentIntentPayload = {
  token: string;
  issuerId: string;
  paymentMethodId: string;
  price: number;
  email?: string;
  user_id: {
    type?: string;
    number?: string;
  };
};

export const useCreatePaymentIntent = () => {
  return useMutation((body: CreatePaymentIntentPayload) =>
    inkapagoAPI.post("/payment", body),
  );
};

type CreatePaymentSavedCardPayload = {
  token: string;
  paymentMethodId: string;
  price: number;
};

export const useCreatePaymentWithSavedCard = () => {
  return useMutation((body: CreatePaymentSavedCardPayload) =>
    inkapagoAPI.post("/payment/saved-card", body),
  );
};

type PaymentDetailResponse = {
  id: string;
  status: string;
  paymentDate?: string;
  sellerName: string;
  price: number;
  totalPrice: number;
  created: string;
};

export const usePaymentDetailQuery = () => {
  const [paymentId, setPaymentId] = useState<string | null>(null);
  const query = useQuery(
    ["paymentData"],
    () => {
      if (!paymentId) return;
      return inkapagoAPI.get("/payment/detail", {
        params: { paymentId: paymentId },
      });
    },
    {
      enabled: !!paymentId,
      refetchOnWindowFocus: false,
    },
  );

  return {
    ...query,
    paymentData: query.data?.data as PaymentDetailResponse | undefined,
    setPaymentId: setPaymentId,
  };
};

type PaymentMethodsResponse = {
  id: string;
  lastFour: string;
  methodBrand: string;
  securityCodeLength: number;
  thumbnail: string;
  issuerId: string;
}[];

export const usePaymentMethodsQuery = (sessionData?: SessionResponse) => {
  const query = useQuery(
    ["paymentMethods"],
    () => {
      return inkapagoAPI.get("/user/cards");
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!sessionData,
    },
  );

  return {
    ...query,
    paymentMethods: query.data?.data as PaymentMethodsResponse | undefined,
  };
};

export type UserPayments = {
  status: string;
  id: string;
  price: number;
  totalPrice: number;
  sellerId: string;
  customerId: string;
  customer?: {
    phone: string;
    name?: string;
  };
  paymentDate?: Date;
  created?: Date;
  withdrawn?: boolean;
  withdrawnRequestId?: boolean;
};

export type UserPaymentsResponse = {
  total: number;
  results: UserPayments[];
};

export const useUserPaymentsQuery = (
  sessionData?: SessionResponse,
  pageSize?: number,
  page?: number,
) => {
  const query = useQuery(
    ["userPayments", pageSize, page],
    () => {
      return inkapagoAPI.get("/user/payments", {
        params: { "page-size": pageSize, page },
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!sessionData,
    },
  );
  return {
    ...query,
    userPayments: query.data?.data as UserPaymentsResponse,
  };
};

type UserSellerInfoResponse = {
  totalToWithdraw: number;
  totalBeingWithdrawn: number;
};

export const useUserSellerInfo = (sessionData?: SessionResponse) => {
  const query = useQuery(
    ["userSellerInfo"],
    () => {
      return inkapagoAPI.get("/user/seller/info");
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!sessionData,
    },
  );
  return {
    ...query,
    userSellerInfo: query.data?.data as UserSellerInfoResponse | undefined,
  };
};

export type WithdrawRequest = {
  status: string;
  sellerId: string;
  created: Date;
  amount: number;
  id: string;
  seller: {
    bank: string;
    name: string;
    bankAccount: string;
  };
};

export type WithdrawRequestResponse = {
  total: number;
  results: WithdrawRequest[];
};

export const useWithdrawRequestsQuery = (
  sessionData: SessionResponse,
  status: string,
  pageSize?: number,
  page?: number,
) => {
  const query = useQuery(
    ["withdrawRequests", status, pageSize, page],
    () => {
      return inkapagoAPI.get("/withdraw-requests", {
        params: { status, "page-size": pageSize, page },
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!sessionData,
    },
  );
  return {
    ...query,
    withdrawRequests: query.data?.data as WithdrawRequestResponse,
  };
};

type SimpleWithdrawRequest = {
  status: string;
  created: Date;
  amount: number;
  withdrawDate?: Date;
  id: string;
};

export type SimpleWithdrawRequestResponse = {
  total: number;
  results: SimpleWithdrawRequest[];
};

export const useSellerWithdrawRequestsQuery = (
  sessionData: SessionResponse,
  pageSize?: number,
  page?: number,
) => {
  const query = useQuery(
    ["withdrawRequests", pageSize, page],
    () => {
      return inkapagoAPI.get("/user/seller/withdraw-requests", {
        params: { "page-size": pageSize, page },
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!sessionData,
    },
  );
  return {
    ...query,
    withdrawRequests: query.data?.data as SimpleWithdrawRequestResponse,
  };
};

export const useCreateWithdrawRequests = () => {
  return useMutation((body: object) =>
    inkapagoAPI.post("/withdraw-requests", body),
  );
};

export const useUpdateToNextStepWithdrawRequests = () => {
  return useMutation((body: { id: string }) =>
    inkapagoAPI.post("/withdraw-requests/next-step", body),
  );
};

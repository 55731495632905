import axios, { AxiosError, AxiosResponse } from "axios";
import Cookies from "js-cookie";

type ApiErrorResponse = {
  error: string;
};

const fetchClient = (baseURL: string, prefixPath: string) => {
  const defaultOptions = {
    baseURL: baseURL + prefixPath,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(config => {
    const authData = Cookies.get("authData");
    if (authData) {
      const { token } = JSON.parse(authData);
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  });
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError<ApiErrorResponse>) => {
      if ([401, 403].includes(error.response?.status as number)) {
        const authData = Cookies.get("authData");
        if (authData) {
          const { paymentId } = JSON.parse(authData);
          if (paymentId && error.response?.data?.error === "SESSION_EXPIRED") {
            window.location.href = `/status/${paymentId}`;
          } else {
            window.location.href = "/error?error=SESSION_EXPIRED";
          }
        } else {
          window.location.href = "/error?error=SESSION_EXPIRED";
        }
        return;
      }
      if (error?.code === "ERR_NETWORK") {
        return Promise.reject(error);
      }
      return Promise.reject(error);
    },
  );
  return instance;
};

export const inkapagoAPI = fetchClient(
  process.env.REACT_APP_API_URL as string,
  "/",
);

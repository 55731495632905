import { Container, Tab, Tabs } from "@mui/material";
import UserPaymentsList from "./UserPaymentsList";
import UserWithdrawRequestsList from "./UserWithdrawRequestsList";
import React, { useState } from "react";

export default function UserPaymentTabs({ ...props }) {
  const { sessionData } = props;
  const [currentTab, setCurrentTab] = useState("user_payments");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Container sx={{ marginBottom: "20px" }}>
        <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
          <Tab value="user_payments" label="Pagos" />
          <Tab value="withdraw_requests" label="Retiros" />
        </Tabs>
      </Container>
      {currentTab === "user_payments" ? (
        <UserPaymentsList sessionData={sessionData} />
      ) : (
        <UserWithdrawRequestsList sessionData={sessionData} />
      )}
    </>
  );
}

import {
  useCreateWithdrawRequests,
  useSessionQuery,
  useUserSellerInfo,
} from "../services/payment";
import React, { CSSProperties, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, CircularProgress, Container, Typography } from "@mui/material";
import Header from "../components/Header";
import { getPENFromCents } from "../utils/price";
import { toast } from "react-toastify";
import UserPaymentsTabs from "../components/UserPaymentsTabs";

export default function UserPayment() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const {
    sessionData,
    setEnabled: enableSessionData,
    isError: isSessionError,
  } = useSessionQuery({ enabled: true });
  const { userSellerInfo, isLoading, isError } = useUserSellerInfo(sessionData);
  const createWithdrawRequest = useCreateWithdrawRequests();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (!params) return;
    const token = params.get("token") ?? null;
    let authData: null | { token: string } = null;
    if (token) {
      authData = {
        token,
      };
    } else {
      const authDataCookie = Cookies.get("authData") ?? null;
      if (authDataCookie) {
        authData = JSON.parse(authDataCookie);
      }
    }
    if (authData) {
      Cookies.set("authData", JSON.stringify(authData), {
        sameSite: "strict",
        expires: 1,
      });
    }
    enableSessionData(true);
  }, [params, enableSessionData]);

  useEffect(() => {
    setButtonDisabled((userSellerInfo?.totalToWithdraw ?? 0) < 5.0);
  }, [userSellerInfo]);
  const onWithdrawButtonClick = () => {
    setButtonDisabled(true);
    createWithdrawRequest.mutateAsync(
      {},
      {
        onSuccess: () => {
          toast.success("Pedido de retiro creado");
          navigate(0);
        },
        onError: err => {
          console.error(
            "No se pudo crear, inténtalo de nuevo o más tarde",
            (err as Error).message,
          );
          toast.error(
            "No se pudo crear el pedido de retiro, intentelo nuevamente.",
          );
          setButtonDisabled(false);
        },
      },
    );
  };

  if (isError || isSessionError) {
    return (
      <>
        <Header />
        <div style={sxErrorText}>
          No se pudo obtener los pagos, revise su conexion e intente nuevamente
          más tarde
        </div>
      </>
    );
  }

  if (isLoading || !sessionData) {
    return (
      <div style={sxGeneralWrapper}>
        <Header />
        <Container
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Container>
      </div>
    );
  }

  return (
    <div style={sxGeneralWrapper}>
      <Header />
      <Typography style={sxStoreTitle}>Pagos</Typography>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          marginBottom: "20px",
          alignItems: "center",
        }}
      >
        <Container sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Disponible:</Typography>
          <Typography variant="h6" sx={{ color: "#35ab3b" }}>
            {getPENFromCents(userSellerInfo?.totalToWithdraw)}
          </Typography>
        </Container>
        <Container sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Pendiente:</Typography>
          <Typography variant="h6" sx={{ color: "#35ab3b" }}>
            {getPENFromCents(userSellerInfo?.totalBeingWithdrawn)}
          </Typography>
        </Container>
        <Button
          variant="contained"
          sx={{ width: "50%", maxWidth: "300px" }}
          onClick={onWithdrawButtonClick}
          disabled={buttonDisabled}
        >
          {" "}
          Retirar
        </Button>
      </Container>
      <UserPaymentsTabs sessionData={sessionData} />
    </div>
  );
}

const sxGeneralWrapper: CSSProperties = {
  position: "relative",
  minHeight: "100vh",
  minWidth: "300px",
  fontFamily: "Karla",
  marginBottom: "10px",
};

const sxStoreTitle: CSSProperties = {
  margin: "40px auto 30px",
  textAlign: "center",
  fontWeight: "700",
  fontFamily: "Karla",
  fontSize: "25px",
  maxWidth: "300px",
};

const sxErrorText: CSSProperties = {
  color: "red",
  fontSize: "24px",
  fontFamily: "Karla",
  margin: "30px auto",
  textAlign: "center",
  padding: "30px",
};

import { inkapagoAPI } from "./axios.config";
import { useMutation } from "react-query";

type UserSellerPayload = {
  phone: string;
  name: string;
  bankAccount: string;
  bank: string;
  bankName: string;
};

type UserAdminPayload = {
  phone: string;
  name: string;
};

export const useRegisterSeller = () => {
  return useMutation((body: UserSellerPayload) =>
    inkapagoAPI.post("/user/seller", body),
  );
};

export const useRegisterAdmin = () => {
  return useMutation((body: UserAdminPayload) =>
    inkapagoAPI.post("/user/admin", body),
  );
};

import React from "react";
import { Button } from "@mui/material";
import { useState, CSSProperties, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../components/Header";
import Cookies from "js-cookie";
import { useSessionQuery } from "../services/payment";
import Loader from "../components/Loader";
import { computeInkapagoComission } from "../utils/price";

const PriceInput = () => {
  const [params] = useSearchParams();
  const [price, setPrice] = useState<null | undefined | number>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { sessionData, setEnabled: enableSessionData } = useSessionQuery({
    enabled: false,
  });

  const goToCardDetails = () => {
    window.localStorage.setItem("price", JSON.stringify(price));
    navigate("/pago");
  };

  useEffect(() => {
    const rawPrice = window.localStorage.getItem("price");
    setPrice(rawPrice ? Number(rawPrice) : undefined);
  }, []);

  useEffect(() => {
    if (!sessionData) return;
    if (sessionData.payment) {
      if (sessionData.payment.status !== "created") {
        navigate(`/status/${sessionData.payment._id}`);
      }
    }
  }, [sessionData, navigate]);

  useEffect(() => {
    if (!params) return;
    const token = params.get("token") ?? null;
    const paymentId = params.get("payment") ?? null;
    let authData: null | { token: string; paymentId: string } = null;
    if (token && paymentId) {
      authData = {
        token,
        paymentId,
      };
    } else {
      const authDataCookie = Cookies.get("authData") ?? null;
      if (authDataCookie) {
        authData = JSON.parse(authDataCookie);
      }
    }
    if (authData) {
      Cookies.set("authData", JSON.stringify(authData), {
        sameSite: "strict",
        expires: 1,
      });
    }
    enableSessionData(true);
  }, [params, enableSessionData]);

  if (!sessionData) return <Loader />;

  const disabled = !sessionData || !price || price > 200000 || price < 500;
  const comission = !disabled ? computeInkapagoComission(price) : null;
  return (
    <div style={sxGeneralWrapper}>
      <Header />
      <div style={sxStoreTitle}>{sessionData?.destUserName ?? ""}</div>
      <div style={sxInputWrapper}>
        <div style={sxInputLabel}>Ingrese el monto a pagar</div>
        {price !== null && (
          <CurrencyInput
            id="input-example"
            name="input-name"
            placeholder="S/ 20.00"
            prefix="S/ "
            maxLength={10}
            decimalsLimit={2}
            defaultValue={
              price ? (price / 100).toFixed(2).toString() : undefined
            }
            onValueChange={value => {
              const priceValue = value
                ? Math.floor(parseFloat(value) * 100)
                : undefined;
              setPrice(priceValue);
            }}
            onBlur={() => {
              if (price && price > 200000) {
                setErrorMessage("El monto máximo es S/20000.00");
              } else if (price && price < 500) {
                setErrorMessage("El monto mínimo es S/5.00");
              } else {
                setErrorMessage("");
              }
            }}
            style={sxInputStyle}
          />
        )}
        {errorMessage && <div style={sxErrorMessage}>{errorMessage}</div>}
        {comission && price && (
          <div style={sxComission}>
            <div style={sxPaymentData}>
              <div>Comisión Inkapago</div>
              <div>S/ {(comission / 100).toFixed(2)}</div>
            </div>
            <div style={sxPaymentData}>
              <div>Monto Total</div>
              <div>S/ {((price + comission) / 100).toFixed(2)}</div>
            </div>
          </div>
        )}
        <Button
          variant="contained"
          style={disabled ? sxDisabledButton : sxEnabledButton}
          disabled={disabled}
          onClick={goToCardDetails}
        >
          Continuar
        </Button>
        <div style={sxInfoSecurity}>
          Integramos nuestra plataforma con proveedores de pago externos para
          garantizar la seguridad de sus datos. Inkapago no guardará los datos
          de su tarjeta directamente.
        </div>
      </div>
    </div>
  );
};
const sxGeneralWrapper: CSSProperties = {
  position: "relative",
  minHeight: "100vh",
  minWidth: "300px",
};
const sxInputStyle: CSSProperties = {
  height: "30px",
  fontSize: "18px",
  color: "#2C2C2C",
  maxWidth: "300px",
  margin: "auto",
  fontWeight: "500",
};
const sxInputWrapper: CSSProperties = {
  margin: "30px auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "10px 30px",
  maxWidth: "500px",
  textAlign: "center",
  fontFamily: "Karla",
};
const sxInputLabel: CSSProperties = {
  fontSize: "18px",
  marginBottom: "20px",
  color: "#2C2C2C",
  fontWeight: "500",
};
const sxErrorMessage: CSSProperties = {
  color: "#CF1212",
  fontSize: "14px",
  margin: "10px auto 0px",
};
const sxButton: CSSProperties = {
  width: "250px",
  margin: "40px auto 0px",
  fontWeight: "600",
  fontFamily: "Karla",
  color: "#ffffff",
  textTransform: "capitalize",
  fontSize: "16px",
};
const sxEnabledButton: CSSProperties = {
  ...sxButton,
  backgroundColor: "#0093e7",
};
const sxDisabledButton: CSSProperties = {
  ...sxButton,
  backgroundColor: "#A8A8A8",
};
const sxInfoSecurity: CSSProperties = {
  marginTop: "30px",
  fontFamily: "Karla",
  color: "#909090",
  fontSize: "14px",
  maxWidth: "350px",
  padding: "0px 20px",
};
const sxStoreTitle: CSSProperties = {
  margin: "40px auto 30px",
  textAlign: "center",
  fontWeight: "700",
  fontFamily: "Karla",
  fontSize: "18px",
  maxWidth: "300px",
};
const sxComission: CSSProperties = {
  margin: "20px 0px -10px",
  padding: "0px 40px",
  fontFamily: "Karla",
  fontSize: "18px",
  width: "250px",
  fontWeight: "500",
  gap: "5px",
  display: "flex",
  flexDirection: "column",
};
const sxPaymentData: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

export default PriceInput;

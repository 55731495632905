import {
  useUpdateToNextStepWithdrawRequests,
  useWithdrawRequestsQuery,
} from "../services/payment";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Pagination,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, CSSProperties, useEffect, useState } from "react";
import { getPENFromCents } from "../utils/price";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { WithdrawRequest } from "../services/payment";

const STATUS_TRANSLATE = new Map<string, string>([
  ["created", "Creado"],
  ["in_progress", "En progreso"],
  ["completed", "Completado"],
]);
export default function WithdrawRequestsList({ ...props }) {
  const { sessionData, status } = props;
  const PAGE_SIZE = 10;
  const [page, setPage] = useState(1);
  const { withdrawRequests, isLoading, isError, isSuccess } =
    useWithdrawRequestsQuery(sessionData, status, PAGE_SIZE, page);
  const [withdrawsAreEnabled, setWithdrawsAreEnabled] = useState(true);
  const [obtainedWithdrawRequests, setObtainedWithdrawRequests] = useState<
    WithdrawRequest[]
  >([]);
  const updateToNextStepWithdrawRequests =
    useUpdateToNextStepWithdrawRequests();

  const handlePageChange = (event: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (isSuccess) {
      setObtainedWithdrawRequests(withdrawRequests.results);
    }
  }, [isSuccess, withdrawRequests]);

  if (isLoading) {
    return (
      <div>
        <Container
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Container>
      </div>
    );
  }
  if (isError) {
    return (
      <>
        <div style={sxErrorText}>
          No se pudo obtener los pedidos de retiro, intente nuevamente
        </div>
      </>
    );
  }

  const withdrawRequest = (id: string) => {
    setWithdrawsAreEnabled(false);
    updateToNextStepWithdrawRequests.mutateAsync(
      { id },
      {
        onSuccess: () => {
          setObtainedWithdrawRequests(
            obtainedWithdrawRequests.filter(request => request.id !== id),
          );
          setWithdrawsAreEnabled(true);
          toast.success("Retiro actualizado");
        },
        onError: err => {
          console.error(err);
          setWithdrawsAreEnabled(true);
          toast.error("No se pudo actualizar el retiro.");
        },
      },
    );
  };

  const buttonText =
    status === "created"
      ? "Marcar como 'En Progreso'"
      : status === "in_progress"
      ? "Marcar como 'Completado'"
      : "-";

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginTop: "10px",
      }}
    >
      {obtainedWithdrawRequests.map(withdraw => {
        return (
          <Card key={withdraw.id} variant="outlined">
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <Typography variant="body2" color="text.secondary">
                <strong>Id:</strong> {withdraw.id}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Monto:</strong> {getPENFromCents(withdraw.amount)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Estado:</strong> {STATUS_TRANSLATE.get(withdraw.status)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Fecha de creación:</strong>{" "}
                {withdraw?.created
                  ? moment(withdraw.created).format("DD/MM/YYYY h:mm a")
                  : "-"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Vendedor:</strong>
              </Typography>
              <Container>
                <Typography variant="body2" color="text.secondary">
                  <strong>Nombre:</strong> {withdraw.seller.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <strong>Banco:</strong> {withdraw.seller.bank}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <strong>Cuenta:</strong> {withdraw.seller.bankAccount}
                </Typography>
              </Container>
              {status !== "completed" ? (
                <div style={{ width: "100%", display: "flex" }}>
                  <Button
                    disabled={!withdrawsAreEnabled}
                    onClick={e => {
                      e.preventDefault();
                      withdrawRequest(withdraw.id);
                    }}
                    sx={{ marginLeft: "auto" }}
                  >
                    {buttonText}
                  </Button>
                </div>
              ) : (
                ""
              )}
            </CardContent>
          </Card>
        );
      })}
      <Container>
        <Pagination
          sx={{ display: "flex", justifyContent: "center" }}
          count={Math.ceil(withdrawRequests.total / PAGE_SIZE)}
          onChange={handlePageChange}
          page={page}
        />
      </Container>
    </Container>
  );
}

const sxErrorText: CSSProperties = {
  color: "red",
  fontSize: "24px",
  fontFamily: "Karla",
  margin: "30px auto",
  textAlign: "center",
  padding: "30px",
};

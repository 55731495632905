import { useState } from "react";
import { useQuery } from "react-query";
import { inkapagoAPI } from "./axios.config";

type ShortURLResponse = {
  fullURL: string;
};

export const useShortURLQuery = () => {
  const [shortStr, setshortStr] = useState<string | null>(null);
  const query = useQuery(
    ["paymentData"],
    () => {
      if (!shortStr) return;
      return inkapagoAPI.get("/shortURL", { params: { shortStr: shortStr } });
    },
    {
      enabled: !!shortStr,
      refetchOnWindowFocus: false,
    },
  );

  return {
    ...query,
    shortURLData: query.data?.data as ShortURLResponse | undefined,
    setshortStr: setshortStr,
  };
};

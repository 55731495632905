import React from "react";
import { Button } from "@mui/material";
import Cookies from "js-cookie";
import { CSSProperties, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useRegisterAdmin } from "../services/admin";
import { useSessionQuery } from "../services/payment";
import { phonePattern } from "../utils/phone";

export default function App() {
  const { register, handleSubmit } = useForm();
  const [params] = useSearchParams();
  const { sessionData, setEnabled: enableSessionData } = useSessionQuery({
    enabled: false,
  });
  const registerSeller = useRegisterAdmin();

  const onSubmit = (data: any) => {
    registerSeller.mutateAsync(data, {
      onSuccess: () => {
        toast.success("Se creó un nuevo admin.");
      },
      onError: () => {
        toast.error("Ya existe un usuario con ese número de whatsapp.");
      },
    });
  };

  useEffect(() => {
    if (!params) return;
    const token = params.get("token") ?? null;
    let authData: null | { token: string } = null;
    if (token) {
      authData = {
        token,
      };
    } else {
      const authDataCookie = Cookies.get("authData") ?? null;
      if (authDataCookie) {
        authData = JSON.parse(authDataCookie);
      }
    }
    if (authData) {
      Cookies.set("authData", JSON.stringify(authData), {
        sameSite: "strict",
        expires: 1,
      });
    }
    enableSessionData(true);
  }, [params, enableSessionData]);

  useEffect(() => {
    if (!params) return;
    let token = params.get("token") ?? null;
    if (!token) {
      token = Cookies.get("token") ?? "";
    }
    Cookies.set("token", token);
    enableSessionData(true);
  }, [params, enableSessionData]);

  const disabled = !sessionData;

  return (
    <div>
      <div style={sxTitle}>Registra al nuevo cliente</div>
      <form onSubmit={handleSubmit(onSubmit)} style={sxForm}>
        <input
          type="text"
          placeholder="Nombre del usuario"
          {...register("name", { required: true, maxLength: 80 })}
          style={sxInput}
        />
        <input
          type="number"
          placeholder="Número de whatsapp (+51987654321)"
          {...register("phone", {
            required: true,
            minLength: 6,
            pattern: phonePattern,
          })}
          style={sxInput}
        />
        <Button
          disabled={disabled}
          type="submit"
          variant="contained"
          style={sxSubmitButton}
        >
          Registrar
        </Button>
      </form>
    </div>
  );
}

const sxTitle: CSSProperties = {
  margin: "30px auto",
  textAlign: "center",
  fontWeight: "700",
  fontFamily: "Karla",
  fontSize: "18px",
};

const sxForm: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  maxWidth: "600px",
  margin: "30px auto",
  padding: "20px",
  gap: "10px",
};

const sxInput: CSSProperties = {
  height: "30px",
  fontFamily: "Karla",
  padding: "5px",
};

const sxSubmitButton: CSSProperties = {
  marginTop: "30px",
};
